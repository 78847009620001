/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Sen_400Regular = require('./Sen_400Regular.ttf');
export const Sen_700Bold = require('./Sen_700Bold.ttf');
export const Sen_800ExtraBold = require('./Sen_800ExtraBold.ttf');
